import Vue from 'vue'
import App from './App.vue'
import router from '@/router/news'
import global from '@/utlis/global.js'
import store from '@/store'
import Meta from 'vue-meta'
import '@/utlis/remcommon.js'
import VueLazyload from 'vue-lazyload'
import { initializeApp} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
Vue.config.productionTip = false
Vue.prototype.$global = global
const loadimage = require('@/assets/news/loading.png')
Vue.prototype.$api = global.api
import '../../utlis/vant'
import document from '@/utlis/document.js'
Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loadimage,
	loading: loadimage,
	attempt: 1,
})
const firebaseConfig = document.firebaseConfig
// const firebaseConfig = firebaseConfig {
//   apiKey: "AIzaSyDUzh3liLqnSIgfSwoRC7drvgsjWc-sZEY",
//   authDomain: "newsonline-h5.firebaseapp.com",
//   projectId: "newsonline-h5",
//   storageBucket: "newsonline-h5.appspot.com",
//   messagingSenderId: "264026317427",
//   appId: "1:264026317427:web:5bd725480dfe422af34006",
//   measurementId: "G-QFWQVP2SZZ"
// };
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
Vue.prototype.$logEvent = (event, params) => {
  logEvent(analytics, event, params);
};
Vue.prototype.$eventrackFb = (msg, method,map = {}) => {
  let params = {
    time: new Date(),
    message: msg,
     method: method,
    ...map,
  };
  logEvent(analytics, msg, params);
};
new Vue({
  router,store,
  render: h => h(App),
}).$mount('#app')
